/**
 * @prettier
 */

@font-face {
	font-family: 'BBCReith';
	src: url('./fonts/BBCReithSans_W_Lt.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'BBCReith';
	src: url('./fonts/BBCReithSans_W_Rg.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'BBCReith';
	src: url('./fonts/BBCReithSans_W_Bd.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

.fontIvyMode {
	font-family: 'Ivy Mode', sans-serif;
}

@font-face {
	font-family: 'Ivy Mode';
	src: url('./fonts/IvyMode-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat.woff2') format('woff2');
}

.fontMontserrat {
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 700;
}
