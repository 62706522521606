/**
 * @prettier
 */

@import url(./font.css);

:root {
	--nightcord: #884499;
	--kanade: #bb6588;
	--mafuyu: #8889cc;
	--ena: #ccaa87;
	--mizuki: #e4a8ca;
}

* {
	box-sizing: border-box;
}

body {
	font-size: 14px;
	font-family: 'BBCReith', 'MicroSoft Yahei', sans-serif;
	margin: 0px;
	cursor: url('./pics/compass.png'), auto;
}

h1,
h2,
h3 {
	margin-top: 20px;
	margin-bottom: 10px;
}

h1 {
	font-size: 36px;
}

h2 {
	font-size: 30px;
}

h3 {
	font-size: 24px;
}

h4 {
	font-size: 18px;
}

p {
	margin: 0 0 10px;
}

img {
	vertical-align: middle;
}

ul {
	margin: 0;
	padding: 0;
}

ul li {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

a {
	text-decoration: none;
}

a:link {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

a:visited {
	text-decoration: none;
}

.noStyleLinkInner {
	color: black;
}

.container {
	width: 100%;
	min-width: 1280px;
	min-height: 100%;
	padding: 0;
	background-image: url('pics/background.png');
	background-size: 20.5px 20.5px;
}

.homePageTab {
	max-width: 1325px;
	padding: 0 72px;
	margin: 0 auto;
	padding-bottom: 70px;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	background-color: var(--mafuyu);
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
	background-color: var(--mafuyu);
}

::-webkit-scrollbar-thumb {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
	background-color: var(--kanade);
}

.innerScrollList {
	overflow-y: scroll;
	overflow-x: hidden;
}

.innerScrollList::-webkit-scrollbar {
	width: 3px;
	height: 3px;
	background-color: var(--mafuyu);
}

.innerScrollList::-webkit-scrollbar-track {
	box-shadow: inset 0 0 4px rgba(0, 0, 0, 0);
	background-color: var(--mafuyu);
}

.innerScrollList::-webkit-scrollbar-thumb {
	box-shadow: inset 0 0 4px rgba(0, 0, 0, 0);
	background-color: var(--kanade);
}

.pointerCursor {
	cursor: url('./pics/compass-select.png'), auto;
}

.unselectable {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.shadowBox {
	box-shadow: 0 1px 5px 1px #aa221380;
	transition: box-shadow 0.3s ease;
}

.shadowBox:hover {
	box-shadow: 0 5px 10px 5px #aa221356;
}

.shadowBoxN {
	box-shadow: 0 0px 5px 1px #aa221356;
}

.backgroundCover {
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
}

.oneLineText {
	display: -webkit-box !important;
	overflow: hidden;
	word-break: break-all;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
}

.fontColor64 {
	color: rgba(0, 0, 0, 0.64);
}

@keyframes down-slide-in-screen {
	from {
		transform: translateY(30%);
		opacity: 0;
	}
	to {
		transform: translateX(0);
		opacity: 1;
	}
}

.downSlideInScreen {
	animation: 0.5s ease-out backwards down-slide-in-screen;
}

@keyframes up-slide-in-screen {
	from {
		transform: translateY(-30%);
		opacity: 0;
	}
	to {
		transform: translateX(0);
		opacity: 1;
	}
}

.upSlideInScreen {
	animation: 0.5s ease-out backwards up-slide-in-screen;
}

.textAlignCenter {
	text-align: center;
}

.textAlignRight {
	text-align: right;
}

.titleBarWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.titleBarWrapper > h2 {
	padding: 5px;
	margin: 15px 0;
}

.titleBarAmount {
	height: 20px;
	padding: 0 6px;
	margin: 0 10px;
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: 12px;
	line-height: 12px;
	background: rgba(0, 0, 0, 0.32);
	color: white;
	border-radius: 10px;
	text-align: center;
}
